<template>
  <div id="app" ref="app" :style="width">
    <router-view/>
  </div>
</template>

<script>
import {Stoke, UserWork} from './api/home'
import wx from "weixin-js-sdk";

export default {
  watch:{
    '$store.state.userInfo'(){
      this.init()
    }
  },
  data() {
    return {
      width: '',
      title: '万人添画',  //分享内容的标题
      // 我是第xxx位添星者｜祝浙大125周年生日快乐
      desc: '参与灿若星辰NFT，浙大校庆“绘”出来', // 分享内容的简短描述
      url: '',
      imgUrl: 'https://hz-mayi.nos-eastchina1.126.net/StarsBg/logoHome.jpg'
    }
  },
  created() {
    const {clientWidth} = document.documentElement

    let path = this.$route.path
    if (path == '/zju/lookwork') {
      this.$router.push('/zju/lookwork')
    } else if (path == '/zju') {
      this.$router.push('/')
    }
    // console.log(this.$route.path)
    if (this.$route.path == '/zju/Stars') {
      this.width = 'width:100%;'
    } else {
      if (clientWidth >= 420) {
        this.width = 'width:375px;margin: 0 auto;'
        // this.$refs.app.style.width='375px'
      }
    }

    // 页面每次刷新加载时候都会去读取localStorage里面的vuex状态
    if (localStorage.getItem("store")) {
      this.$store.replaceState(
          Object.assign(
              {},
              this.$store.state,
              JSON.parse(localStorage.getItem("store")) //这里存的是可能经过mutions处理过的state值，是最新的,所以放在最后
          )
      )
    }
    // 在页面刷新之前把vuex中的信息存到sessionStoreage
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("store", JSON.stringify(this.$store.state));
    });

    //检查是否存在登录状态有效  否则删除状态
    setTimeout(() => {
      let path = this.$route.path
      if (path !== '/zju/lookwork') {
        let _id = this.$store.state.userInfo._id
        if (!_id) {
          this.$store.state.userInfo = {}
          this.$router.push('/')

        }
      }
    }, 100)

    this.$root.$on('init',()=>{
      this.init()
    })
  },
  mounted() {
    this.init()
    this.$store.state.paintingStart = true
  },
  beforeDestroy() {
    localStorage.setItem("store", JSON.stringify(this.$store.state));
  },
  methods: {
    init() {
      let url ='https://wrth.3qlab.com';
      this.url = url
      let userInfo = this.$store.state.userInfo
      if (userInfo._id) {
        let id = userInfo._id
        UserWork({id}).then(res => {
          let text = res.data
          // 我是第xx位｜祝浙大125生日快乐。
          // 我是第xxx位添星者｜祝浙大125周年生日快乐
          this.title = '我是第' + String(text) + '位添星者｜祝浙大126周年生日快乐'
          setTimeout(() => {
            this.getKeyParams()
          }, 500)
        })
      } else {
        UserWork({id: 'XXXX'}).then(res => {
          let text = res.data
          this.title = '我是第' + String(text) + '位添星者｜祝浙大126周年生日快乐'
          setTimeout(() => {
            this.getKeyParams()
          }, 500)
        })
      }
    },
    async getKeyParams() {
      let url = window.location.href;
      Stoke({url}).then((res) => {
        let signature = res.signature;  //签名
        let noncestr = res.noncestr;  //随机串
        let timestamp = res.timestamp; //时间戳
        wx.config({
          // debug: true, // 开启调试模式
          appId: "wxa8ce8daa4ddb25f2", // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: noncestr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData", "onMenuShareTimeline","updateTimelineShareData","onMenuShareAppMessage","onMenuShareQQ","onMenuShareWeibo","onMenuShareQZone"], // 必填，需要使用的JS接口列表
        });
        wx.ready(() => {
          //朋友分享
          wx.updateAppMessageShareData({
            title: this.title, // 分享标题
            desc: this.desc, // 分享描述
            link: this.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: this.imgUrl, // 分享图标
          });
          //朋友圈分享
          wx.onMenuShareTimeline({
            title:'参与灿若星辰NFT，浙大校庆“绘”出来', // 分享标题
            link: this.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://hz-mayi.nos-eastchina1.126.net/StarsBg/logoHome.jpg', // 分享图标
          })

          wx.updateTimelineShareData({  //需在用户可能点击分享按钮前就先调用
            title: '参与灿若星辰NFT，浙大校庆“绘”出来', // 分享标题
            link: this.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://hz-mayi.nos-eastchina1.126.net/StarsBg/logoHome.jpg', // 分享图标
          })

          wx.onMenuShareAppMessage({
            title: this.title, // 分享标题
            desc: this.desc, // 分享描述
            link: this.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: this.imgUrl, // 分享图标
            type: '', // 分享类型,music、video或link，不填默认为link
            dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
          })

          wx.onMenuShareQQ({
            title: this.title, // 分享标题
            desc: this.desc, // 分享描述
            link: this.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: this.imgUrl, // 分享图标
          })

          wx.onMenuShareWeibo({
            title: this.title, // 分享标题
            desc: this.desc, // 分享描述
            link: this.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: this.imgUrl, // 分享图标
          })

          wx.onMenuShareQZone({
            title: this.title, // 分享标题
            desc: this.desc, // 分享描述
            link: this.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: this.imgUrl, // 分享图标
          })
        })

      })
    },
    offsetHeight() {
      return this.$refs.app.offsetHeight
    },
    wxShare() {

    },
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  height: 100%;
}


div::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

div::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}

div::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: #ededed;
}


@font-face {
  font-family: 'Vonwaon';
  src: url('./assets/font/Vonwaon.ttf');
  font-weight: normal;
  font-style: normal;
}


</style>
