import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import vcolorpicker from 'vcolorpicker'
Vue.use(vcolorpicker)

Vue.use(VueCookies)


Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.matched.length === 0) {  // 如果未匹配到路由
    from.name ? next({ name: from.name }) : next('/')
  } else {
    next()  // 如果匹配到正确跳转
  }
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
