import request from "../utils/request";

export const initHome = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "index/home",
        method: "get",
        params: query,
    });
};


export const Stoke = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "wx/ticket",
        method: "get",
        params: query,
    });
};



export const UserWork = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "index/user/work",
        method: "get",
        params: query,
    });
};
